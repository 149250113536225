import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBQwipNpVsMksAIeeIKKubUROgGUN-qW5M",
  authDomain: "hweport-5febb.firebaseapp.com",
  projectId: "hweport-5febb",
  storageBucket: "hweport-5febb.appspot.com",
  messagingSenderId: "203566738291",
  appId: "1:203566738291:web:20403bd6cd8d74032f3306",
  measurementId: "G-9QWFMHGXMK"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
